import { Component, OnInit } from '@angular/core';
import { NgbModal, NgbModalRef } from '@ng-bootstrap/ng-bootstrap';
import { ToastrService } from 'ngx-toastr';
import { AcordoJuridicoService } from 'src/app/services/acordo-juridico.service';
import { ModalPagamentoComponent } from '../modal-pagamento/modal-pagamento.component';
import { ArquivoService } from 'src/app/services/arquivo.service';
import { UtilsService } from 'src/app/utils/utils.service';
import Swal from 'sweetalert2';
import { RSA_NO_PADDING } from 'constants';
import { Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Guid } from 'guid-typescript';

@Component({
    selector: 'app-modal-parcelas',
    templateUrl: './modal-parcelas.component.html',
    styleUrls: ['./modal-parcelas.component.scss']
})
export class ModalParcelasComponent implements OnInit {

    private modalRef: NgbModalRef;
    
    private acordos: any;

    private acordoId: string = null;
    private referenciaId: string = null;

    public loadingAcordo: boolean = false;
    public loadingAcordoHonorario: boolean = false;    

    public raiz: string = '';

    constructor(
        private acordoJuridicoService: AcordoJuridicoService,
        public toastrService: ToastrService,
        public modalService: NgbModal,
        private arquivoService: ArquivoService,
        public utilsService: UtilsService,
        private router: Router,
        private spinner: NgxSpinnerService,
    ) { }

    ngOnInit(): void {
        if (this.router.url.indexOf('/dashboard/') > -1) {
            this.raiz = '/dashboard'
        } else {
            this.raiz = '/operacionais'
        }

        this.carregarAcordo();
            
    }
    
    calcularTotais(acordo) {        
        acordo.totalPago = acordo.dividas.filter(ac=>ac.valorPago != null).map(ac=>ac.valorPago).reduce((acc, cur)=>{ return acc+ cur}, 0);
        acordo.totalDesconto = acordo.dividas.filter(ac=>ac.valorDesconto != null).map(ac=>ac.valorDesconto).reduce((acc, cur)=>{ return acc+ cur}, 0);
        acordo.totalAberto= acordo.valorTotal - acordo.totalPago - acordo.totalDesconto;
    }

    carregarAcordo() {
        this.loadingAcordo = true;

        this.acordoJuridicoService.obterAcordosPorReferencia(this.referenciaId).subscribe(res=>{
            this.acordos = res.data;
            console.log(res.data);
            this.acordos.forEach(ac => this.calcularTotais(ac));

            this.loadingAcordo = false;         
            this.spinner.hide();
        }, err=>{
            this.toastrService.error("Erro ao carregar acordos");
        });
    }

    abrirModalPagamentoAcordo(acordo, parcela){

        if(parcela.tipoParcela == "Honorário"){
            this.showModalPagamento(acordo, parcela);
            return;
        }

        this.acordoJuridicoService.checarParcelaPossuiSuccessFeeParaAtender(parcela.id)
            .subscribe(res=>{
                if(res.data){
                    this.showModalPagamento(acordo, parcela);
                }else{
                    const swalWithBootstrapButtons = Swal.mixin({
                        customClass: {
                            confirmButton: 'btn btn-padrao btn-lg mr-3',
                            cancelButton: 'btn btn-default btn-lg'
                        },
                        buttonsStyling: false
                    });
            
                    swalWithBootstrapButtons.fire({
                        title: 'Não foram encontrados dados de faturamento do tipo Success Fee para o valor da parcela informada, deseja incluir agora?',
                        icon: 'question',
                        showCancelButton: true,
                        confirmButtonText: 'Sim',
                        cancelButtonText: 'Não',
                        reverseButtons: true
                    }).then((result) => {
                        if (result.value) {                                 
                            this.acordoJuridicoService.obterChaveProcessoPorParcela(parcela.id)
                                .subscribe(res=>{                                                                            
                                    const url = this.raiz + '/processos-judiciais/editar/' + res.data;
                                    
                                    window.open(url, '_blank');

                                }, err=>{
                                    this.toastrService.error('Não foi possível carregar o processo!', 'Atenção', { timeOut: 10000 });
                                })
                        }
                    });
                }
            },err=>{
                this.toastrService.error('Não foi possível carregar!', 'Atenção', { timeOut: 10000 });
            })



        
    }

    showModalPagamento(acordo, parcela){
        
        const modalRef = this.modalService.open(ModalPagamentoComponent, {
            size: "lg",
            backdrop : 'static',
            keyboard : false
        });

        modalRef.componentInstance.parcela = parcela;        
        modalRef.componentInstance.acordo = acordo;
        modalRef.componentInstance.modalRef = modalRef;

        modalRef.result
        .then((result) => {
            this.carregarAcordo();
        });
    }

    downloadAnexo(acordo){        
        this.arquivoService.downloadArquivo(acordo.arquivoId).subscribe(data=>{
            this.utilsService.renderDownload(data, acordo.arquivoNome as string);            
        },err=>{            
            this.toastrService.error('Não foi possível fazer o download!', 'Atenção', { timeOut: 10000 });
        })
    }

    obterAcordoId(acordoPrincipal){        
        if(acordoPrincipal)
            return this.acordos.find(c=>c.tipoAcordoJudicialId != 2).id;
        else
            return this.acordos.find(c=>c.tipoAcordoJudicialId == 2).id;
    }

    possuiAcordoPrincipal(){        
        if(this.acordos == null || this.acordos == undefined) return false;

        return this.acordos.filter(c=>c.tipoAcordoJudicialId != 2).length;
    }

    possuiAcordoHonorario(){        
        if(this.acordos == null || this.acordos == undefined) return false;

        return this.acordos.filter(c=>c.tipoAcordoJudicialId == 2).length;
    }

    dismiss(){
        this.modalRef.dismiss();
    }

    close(){
        this.modalRef.close();
    }

    formatarParcela(parcela:number, parcelas:number){
        
        const parcelaFormat = parcela < 9 ? '0'+ parcela : parcela;
        const quantidadeParcelas = parcelas < 9 ? '0'+ parcelas : parcelas;

        return `${parcelaFormat}/${quantidadeParcelas}`;
    }

    removerParcela(parcelaId:Guid){
        
        const swalWithBootstrapButtons = Swal.mixin({
            customClass: {
                confirmButton: 'btn btn-padrao btn-lg mr-3',
                cancelButton: 'btn btn-default btn-lg'
            },
            buttonsStyling: false
        });

        swalWithBootstrapButtons.fire({
            title: 'Deseja excluir o lançamento da parcela?',
            icon: 'question',
            showCancelButton: true,
            confirmButtonText: 'Sim',
            cancelButtonText: 'Não',
            reverseButtons: true
        }).then((result) => {
            if (result.value) {
                this.spinner.show();
                debugger;
                this.acordoJuridicoService.excluirPagamento(parcelaId)
                    .subscribe(res=>{
                        this.carregarAcordo();
                        this.spinner.hide();
                    }, err=>{
                        this.toastrService.error('Não foi possível fazer excluir!', 'Atenção', { timeOut: 10000 });
                        this.spinner.hide();
                    })
            }
        });
    }
}
